import React from 'react';
import './App.css';

class Intro extends React.Component {
  render() {
    return(
      <div className="Intro">
        <p>
          The Shopping Units app helps you quickly compare prices while shopping.
          Even products that use different types of sizing units, like grams vs ounces. 
          This is web version of the app that's already available on the Playstore, more information <a rel="noopener noreferrer" target="_blank" href="https://blue58.co/shopping-units/">here</a>.
        </p>
        <p>
          Just enter the price, the size, and the type of measurement the product uses.<br />
          If it is a package with more than 1 item, like a 6 pack of drinks, then you can enter the quantity of the pack.<br />
          The cheapest item will be <span className="cheapest">HIGHLIGHTED</span>.
        </p>
      </div>
      )
  }
}

export default Intro;