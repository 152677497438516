import React from 'react';
import './App.css';

class Footer extends React.Component {
  render() {
    return(
      <div className="Footer">
        <div>
          Shopping Units is also available for Android. There are the <a href="https://play.google.com/store/apps/details?id=com.robertchankh.shoppingunits.paid">Ad-Free</a> and <a href="https://play.google.com/store/apps/details?id=com.robertchankh.shoppingunits">Ad-Supported</a> versions.
        </div>
        <div>
          Created by <a href="https://blue58.co/">blue 58</a>.
        </div>
      </div>
      )
  }
}

export default Footer;