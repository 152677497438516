import React from 'react';
import _ from 'lodash';
import './App.css';
import Intro from './Intro';
import Footer from './Footer';

const CONVERSIONS = {
  unit: 1,
  g_oz: 28.3495,
  g_catty: 604.78982,
  oz_lb: 16,
  ml_floz: 29.5735,
  floz_gal: 128
};

const BASE_UNITS = {
  units: [ "units" ],
  weights: [ "oz", "catty", "grams", "kilograms", "pounds" ],
  liquids: [ "fluid ounces", "millilitres", "gallons" ]
};

const NAME_PREF = "Item #";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src="./icon-full.png" alt="Shopping Units App Logo" />
        </div>
      </header>
      <div>
        <Intro />
      </div>
      <div className="App-holder">
        <ConvertApp />
      </div>
      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
}

class ConvertApp extends React.Component { 
  constructor(props) {
    super(props)
    this.state = {
      price: 1,
      quantity: 1,
      unit_value: 1,
      unit_type: "units",
      name: NAME_PREF + 1,
      select_items: [],
      converted_items: [],
      cheapest_id: -1,
      item_index: 1,
      errors: {
        price: '',
        unit_value: '',
        quantity: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.calculateUnits = this.calculateUnits.bind(this);
    this.clearList = this.clearList.bind(this);


  }

  componentDidMount() {
    this.updateSelectOptionList();
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch(name) {
      case "price":
        errors.price = value <= 0 ? "Price cannot be 0 or less." : "";
      break;
      case "unit_value":
        errors.unit_value = value <= 0 ? "Unit value cannot be 0 or less." : "";
      break;
      case "quantity":
        errors.quantity = value <= 0 ? "Quantity cannot be 0 or less." : "";
      break;
      default:
      break;
    }

    this.setState({
      errors,
      [name] : value
    })
  }

  clearList() {
    this.setState({
      price: 1,
      quantity: 1,
      unit_value: 1,
      unit_type: "units",
      name: NAME_PREF + 1,
      select_items: [],
      converted_items: [],
      cheapest_id: -1,
      item_index: 1,
      errors: {
        price: '',
        unit_value: '',
        quantity: ''
      }
    }, () => {
      this.updateSelectOptionList();
    });
  }

  convertUnits(unit, price) {
    var unit_price = price / unit;
    return [
      "$" + unit_price.toFixed(2) + " per unit."
    ]
  }

  convertLiquidUnits(unit_in_mls, price) {
    var ml_price = (price / unit_in_mls) * 100;
    var floz_price = (price / unit_in_mls) * CONVERSIONS.ml_floz;
    var gal_price = floz_price * CONVERSIONS.floz_gal;

    // should do number format for price but later.

    return [
     "$" + ml_price.toFixed(2) + " per 100 mls.",
     "$" + floz_price.toFixed(2) + " per fluid ounce",
     "$" + gal_price.toFixed(2) + " per gallon."
    ]
  }

  convertWeightUnits(unit_in_grams, price) {
    // 28.3495
    const grams_price = (price / unit_in_grams) * 100; // $100 / oz. $100 / 28.3495 = $3.52 / g.
    const kg_price = grams_price * 10;

    const oz_price = (price / unit_in_grams) * CONVERSIONS.g_oz;
    const lb_price = oz_price * CONVERSIONS.oz_lb;
    const catty_price = (price / unit_in_grams) * CONVERSIONS.g_catty;

    // should do number format for price but later.

    return [
     "$" + grams_price.toFixed(2) + " per 100 grams.",
     "$" + kg_price.toFixed(2) + " per kilogram",
     "$" + oz_price.toFixed(2) + " per ounce.",
     "$" + lb_price.toFixed(2) + " per pound.",
     "$" + catty_price.toFixed(2) + " per catty."
    ]
  }

  validateForm(errors) {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  calculateUnits() {

    if(!this.validateForm(this.state.errors)) {
      return;
    }

    const item = {
      uid: _.uniqueId(),
      name: this.state.name,
      price: this.state.price,
      quantity: this.state.quantity,
      unit_value: this.state.unit_value,
      unit_type: this.state.unit_type,
      price_per_unit: 0,
      converted_units: []
    }

    var unitInGrams = 0;
    var unitInMls = 0;
    var unitInUnits = 0;
    var unitCategory = "";
    
    // get conversion s
    switch(item.unit_type) {
      case "oz":
        unitCategory = "weights";
        unitInGrams = item.unit_value * item.quantity * CONVERSIONS.g_oz;
        item.price_per_unit = item.price / unitInGrams;
        item.converted_units = this.convertWeightUnits(unitInGrams, item.price);
        break;
      case "grams":
        unitCategory = "weights";
        unitInGrams = item.unit_value * item.quantity;
        item.price_per_unit = item.price / unitInGrams;
        item.converted_units = this.convertWeightUnits(unitInGrams, item.price);
        break;
      case "kilograms":
        unitCategory = "weights";
        unitInGrams = item.unit_value * item.quantity * 1000;
        item.price_per_unit = item.price / unitInGrams;
        item.converted_units = this.convertWeightUnits(unitInGrams, item.price);
        break;
      case "pounds":
        unitCategory = "weights";
        unitInGrams = item.unit_value * item.quantity * (CONVERSIONS.g_oz * CONVERSIONS.oz_lb);
        item.price_per_unit = item.price / unitInGrams;
        item.converted_units = this.convertWeightUnits(unitInGrams, item.price);
        break;
      case "catty":
        unitCategory = "weights";
        unitInGrams = item.unit_value * item.quantity * (CONVERSIONS.g_catty);
        item.price_per_unit = item.price / unitInGrams;
        item.converted_units = this.convertWeightUnits(unitInGrams, item.price);
        break;
      case "millilitres":
        unitCategory = "liquids";
        unitInMls = item.unit_value * item.quantity;
        item.price_per_unit = item.price / unitInMls;
        item.converted_units = this.convertLiquidUnits(unitInMls, item.price);
        break;
      case "fluid ounces":
        unitCategory = "liquids";
        unitInMls = item.unit_value * item.quantity * CONVERSIONS.ml_floz;
        item.price_per_unit = item.price / unitInMls;
        item.converted_units = this.convertLiquidUnits(unitInMls, item.price);
        break;
      case "gallons":
        unitCategory = "liquids";
        unitInMls = item.unit_value * item.quantity * (CONVERSIONS.ml_floz * CONVERSIONS.floz_gal);
        item.price_per_unit = item.price / unitInMls;
        item.converted_units = this.convertLiquidUnits(unitInMls, item.price);
        break;
      default:
        unitCategory = "units";
        unitInUnits = item.unit_value * item.quantity;
        item.price_per_unit = item.price / unitInUnits;
        item.converted_units = this.convertUnits(item.unit_value * item.quantity, item.price)
        break;
    }

    // check if this item is the cheapest
    const parent = this;
    this.setState({
      converted_items: this.state.converted_items.concat(item)
    }, () => {
      const newItemIndex = this.state.item_index + 1;
      const cheapestId = parent.getCheapestId(item.uid, item.price_per_unit);
      this.setState({
        name: NAME_PREF + newItemIndex,
        item_index: newItemIndex,
        cheapest_id: cheapestId,
        price: 1,
        quantity: 1,
        unit_value: 1
      }, () => {
        this.updateSelectOptionList(unitCategory);
      })
    });
  }

  getCheapestId(itemId, itemPricePerUnit) {
    if(this.state.cheapest_id < 0) {
      // no cheapest id set
      return itemId;
    } else {
      // cheapest id set, retrieve previous cheapest ID and compare.
      // get last cheapest item.
      const currCheapestId = this.state.cheapest_id;
      const currCheapest = _.find(this.state.converted_items, function(obj) {
        return obj.uid === currCheapestId;
      });
      return currCheapest ? (currCheapest.price_per_unit >= itemPricePerUnit ? itemId : currCheapestId) : itemId;
    }
  }

  findCheapest(itemArray) {
    var currCheapestId = -1;
    var sortedArray = _.sortBy(itemArray, (item) => {
      return item.price_per_unit;
    });
    currCheapestId = sortedArray[0] ? (sortedArray[0].uid ? sortedArray[0].uid : -1) :  -1;
    this.setState({
      cheapest_id: currCheapestId
    });
  }

  deleteItem(itemId) {
    if(this.state.converted_items.length === 1) {
      // last item in list, reset everything.
      this.clearList();
    } else {
      // not the last item.
      // copy list
      var convertedItems = [...this.state.converted_items];
      var itemIndex = _.findIndex(this.state.converted_items, (item) => {
        return item.uid === itemId
      });
      if(itemIndex >= 0) {
        convertedItems.splice(itemIndex, 1);
        var newStates = {
          converted_items: convertedItems
        }
        if(itemId === this.state.cheapest_id) {
          // removed item was the cheapest item.
          // reset it.
          newStates.cheapest_id = -1;
        }
        const parent = this;
        this.setState(newStates, () => {
          parent.findCheapest(this.state.converted_items);
        });
      }
    }
  }

  updateSelectOptionList(category) {
    var selectList = [];
    const cat = category ? category : "";

    if(cat === "") {
      selectList = [ ...BASE_UNITS.units, ...BASE_UNITS.weights, ...BASE_UNITS.liquids ]
    } else {
      selectList = [ ...BASE_UNITS[cat] ]
    }
    selectList = selectList.map((selectItem) => (
      <option key={_.uniqueId()} value={selectItem}>{selectItem}</option>
    ))

    this.setState({
      select_items: selectList
    })
  }


  render() {
    const parent = this;
    const {errors} = this.state;
    return (
      <div className="Convert-app">
        <div className="Convert-form">
          <form>
            <div className="Form-row">
              <label>
                Price: <input className="Form-input" name="price" type="number" min="0" value={this.state.price} onChange={this.handleChange} />
              </label>
              <label>
                Unit: <input className="Form-input" name="unit_value" type="number" min="1" value={this.state.unit_value} onChange={this.handleChange} />
              </label>
              <label>
                Unit Type: <select className="Form-input" name="unit_type" value={this.state.unit_type} onChange={this.handleChange}>
                  {this.state.select_items}
                </select>
              </label>
            </div>
            <div className="Form-row">
              <label>Quantity: <input className="Form-input" name="quantity" type="number" min="1" value={this.state.quantity} onChange={this.handleChange} />
              </label>
              <label>Name: <input className="Form-input" name="name" type="text" value={this.state.name} onChange={this.handleChange} />
              </label>
            </div>
            <div className="Form-row">
              <button className="myButton" type="button" onClick={this.calculateUnits}>Calculate</button>
              <button className="myButton myButtonClear" type="button" onClick={this.clearList}>Clear List</button>
            </div>
          </form>
        </div>
        <div className="Form-messages">
          {errors.price.length > 0 && <span className='error'>{errors.price}</span>}
          {errors.unit_value.length > 0 && <span className='error'>{errors.unit_value}</span>}
          {errors.quantity.length > 0 && <span className='error'>{errors.quantity}</span>}
        </div>
        <div className="Result-section">
          {
            this.state.converted_items.map(item => {
              return (
                <div className={"Item-container " + (item.uid === parent.state.cheapest_id ? "cheapest" : "")} key={_.uniqueId()}>
                  <ConvertedItem cheapest_id={parent.cheapest_id} item={item} key={item.uid} />
                  <button className="myButton myButtonClear" type="button" onClick={() => parent.deleteItem(item.uid)}>Delete Item</button>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

class ConvertedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: props.item.uid,
      name: props.item.name,
      price: props.item.price,
      price_per_unit: props.item.price_per_unit,
      unit_value: props.item.unit_value,
      unit_type: props.item.unit_type,
      quantity: props.item.quantity,
      converted_units: []
    }
    this.state.converted_units = props.item.converted_units.map((converted_string) => (
      <li key={_.uniqueId()}>{converted_string}</li>
      )
    );
  }

  render() {
    return (
      <div className="Converted-item">
        <h4>{this.state.name}</h4>
        {this.state.quantity} item(s) that cost(s) ${this.state.price}.<br />
        Each item is {this.state.unit_value} {this.state.unit_type}.
        <ul>
          {this.state.converted_units}
        </ul>
      </div>
    )
  }
}

export default App;



















